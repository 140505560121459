export enum MixpanelEventName {
  HEALTH_RECORDS_REQUESTED = 'Health Records Requested',
  APP_CLOSED = 'Provider app closed',
  TERMS_OF_SERVICE_LINK_CLICKED = 'Terms of Service link clicked',
  BAA_LINK_CLICKED = 'BAA link clicked',
  PRIVACY_POLICY_LINK_CLICKED = 'Privacy Policy link clicked',
  TERMS_AND_PRIVACY_CONTINUE_BUTTON_CLICKED = 'Terms & Privacy continue button clicked',
  EDIT_WAYPOINTS_BUTTON_CLICK = 'Edit waypoints button clicked',
  EDIT_WAYPOINTS_PANEL_CLOSED = 'Edit waypoints panel closed',
  SEND_SUGGESTIONS_BUTTON_CLICK = 'Send Suggestions button clicked',
  SEND_SUGGESTIONS_CONFIRMATION_BUTTON_CLICK = 'Send Suggestions confirmation button clicked',
  SEND_SUGGESTIONS_CANCEL_BUTTON_CLICK = 'Send Suggestions cancel button clicked',
  REVOKE_SUGGESTIONS_BUTTON_CLICK = 'Revoke suggestions button clicked',
  REVOKE_SUGGESTIONS_CONFIRMATION_BUTTON_CLICK = 'Revoke suggestions confirmation button clicked',
  REVOKE_SUGGESTIONS_CANCEL_BUTTON_CLICK = 'Revoke Suggestions cancel button clicked',
}

export enum Result {
  SUCCESS = 'Success',
  FAIL = 'Fail',
}
