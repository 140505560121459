import * as React from 'react';
import { Skeleton, TableCell, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { whoIam } from 'utils/apiUrls';
import { NotSpecified } from 'utils/constants';
import { capitalize } from 'utils/appUtils';

interface PatientLocusOfControlProps {
  id: string;
}

export default function PatientLocusOfControl({ id }: PatientLocusOfControlProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patienId } = useParams();

  const locusOfControl = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patienId,
      id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
    },
  );


  if (locusOfControl.isFetching) {
    return <TableCell><Skeleton /></TableCell>;
  }

  const result = locusOfControl?.data?.results[0];

  const isBothLocusOfControl = result?.description === 'both';

  const locusOfControlResult = result ? `${capitalize(result.description)} ${isBothLocusOfControl ? 'external and internal locus of control' : 'locus of control'} (${result.score} of ${result.totalScore})` : NotSpecified;

  return (
    <TableCell>
      <Typography>
        {locusOfControlResult}
      </Typography>
    </TableCell>
  );
}
