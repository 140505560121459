/* eslint-disable no-nested-ternary */
import { Box, Grid, Typography } from '@mui/material';
import AppleHealthIcon from 'components/Common/AppleHealthIcon';
import { useHttp } from 'hooks/use-fetch';
import AppleHealthActivityChart from 'pages/Dashboard/components/AppleHealthActivityChart';
import AppleHealthActivitySummaryChart, {
  DataKey,
} from 'pages/Dashboard/components/AppleHealthActivitySummaryChart';
import AppleHealthChartsWrapper from 'pages/Dashboard/components/AppleHealthChartsWrapper';
import AppleHealthSleepChart from 'pages/Dashboard/components/AppleHealthSleepChart';
import SleepChart from 'pages/Dashboard/components/SelfReportedSleepChart';
import { PatientCtx } from 'pages/Dashboard/constants';
import {
  fetchGamesTracking,
  fetchWaypointSources,
  fetchWaypointsTracking,
  fetchWaypointTracking,
} from 'pages/Dashboard/services/tracking.services';
import { fetchPatientWaypointList } from 'pages/Dashboard/services/waypoints.services';
import {
  ListWaypointDetails,
  StroopTimeSeriesAPIResponse,
  WaypointTracking,
  WaypointTrackingAPIResponse,
  WaypointType,
} from 'pages/Dashboard/types/waypoints.types';
import {
  TimeFrameOptions,
  defaultWaypointTracking,
  getChartDateRanges,
} from 'pages/Dashboard/utils/trackingUtils';
import * as React from 'react';
import { useQueries, useQuery } from 'react-query';
import theme from 'theme';
import { gamesUrls, trackingUrls, wayPointsUrls } from 'utils/apiUrls';
import { MixpanelEventName } from 'utils/constants';
import { Logger } from 'utils/logger';
import { trackMixpanelEvent } from 'utils/utilMethods';

const barSizeMap = {
  [`${TimeFrameOptions.LAST_7_DAYS}-4`]: 34,
  [`${TimeFrameOptions.LAST_30_DAYS}-4`]: 8,
  [`${TimeFrameOptions.LAST_3_MONTHS}-4`]: 67,
  [`${TimeFrameOptions.LAST_6_MONTHS}-4`]: 38,
  [`${TimeFrameOptions.LAST_7_DAYS}-6`]: 53,
  [`${TimeFrameOptions.LAST_30_DAYS}-6`]: 13,
  [`${TimeFrameOptions.LAST_3_MONTHS}-6`]: 106,
  [`${TimeFrameOptions.LAST_6_MONTHS}-6`]: 61,
};

type Waypoint = {
  id: string;
  title?: string;
  subTitle?: string;
};

interface PatientTrackingProps {
  patientId: string;
}

export default function PatientTrackingSummary({ patientId }: PatientTrackingProps) {
  const { http, providerId } = useHttp();
  const timeFrame = TimeFrameOptions.LAST_30_DAYS;
  const [sourceId, setSourceId] = React.useState<string>('');
  const [dataMap, setDataMap] = React.useState<Record<string, boolean>>({});
  const onError = (message: string) => {
    Logger.error(message);
  };

  const currentWaypoint = useQuery(
    [
      `${trackingUrls.waypoints.queryUrl}`,
      providerId,
      patientId,
      TimeFrameOptions.LAST_30_DAYS,
      'current',
    ],
    {
      queryFn: fetchWaypointsTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
      enabled: !!patientId && !!providerId,
      select: (data) => {
        const filteredWaypoints = data?.waypoints.filter(
          (waypoint) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_APPLE_HEALTH,
        );

        return {
          waypoints: filteredWaypoints,
        } as WaypointTrackingAPIResponse;
      },
    },
  );
  const previousWaypoint = useQuery(
    [
      `${trackingUrls.waypoints.queryUrl}`,
      providerId,
      patientId,
      TimeFrameOptions.LAST_30_DAYS,
      'previous',
    ],
    {
      queryFn: fetchWaypointsTracking(http.get),
      onError: () => onError('Error fetching patient waypoint'),
      enabled: !!patientId && !!providerId,
      select: (data) => {
        const filteredWaypoints = data?.waypoints.filter(
          (waypoint) => waypoint.waypoint.type !== WaypointType.WAYPOINT_TYPE_APPLE_HEALTH,
        );

        return {
          waypoints: filteredWaypoints,
        } as WaypointTrackingAPIResponse;
      },
    },
  );

  const onChangeSource = React.useCallback(
    (source: string) => {
      trackMixpanelEvent(
        MixpanelEventName.AH_SLEEP_CHART_SOURCE_CHANGED,
        {
          'patient id': patientId ?? '',
        },
        true,
      );
      setSourceId(source);
    },
    [patientId],
  );

  const fetchPatientWaypointsResult = useQuery(
    [`${wayPointsUrls.listPatientWaypoints.queryUrl}`, patientId],
    {
      queryFn: fetchPatientWaypointList(http.get),
      enabled: !!providerId && !!patientId,
    },
  );

  const waypointIndexes = React.useMemo(() => ({} as Record<string, number>), []);
  const appleHealthWaypointMap = React.useMemo(() => ({} as Record<string, string>), []);
  const appleHealthWaypoints = React.useMemo(
    () => fetchPatientWaypointsResult?.data?.waypoints?.reduce((acc, w, i) => {
      if (w?.waypoint?.type === WaypointType.WAYPOINT_TYPE_APPLE_HEALTH) {
        appleHealthWaypointMap[w?.waypoint?.name] = w?.waypoint?.id;
        if (w?.waypoint?.name !== 'sleepAppleHealth') {
          acc.push({
            id: w?.waypoint?.id,
            title: w?.waypoint?.displayName,
            subTitle: w?.waypoint?.description,
          });
        }
      }

      return acc;
    }, [] as Waypoint[]) || [],
    [appleHealthWaypointMap, fetchPatientWaypointsResult?.data?.waypoints],
  );

  const fetchPatientWaypointSourcesResult = useQuery(
    [`${trackingUrls.waypointSources.queryUrl}`, providerId, patientId],
    {
      queryFn: fetchWaypointSources(http.get),
      enabled: !!providerId && !!patientId,
    },
  );

  const sourceOptions = React.useMemo(
    () => fetchPatientWaypointSourcesResult?.data?.sources?.map((s) => {
      if (s.isSelected) {
        onChangeSource(s.sourceId);
      }
      return {
        label: s.sourceName,
        value: s.sourceId,
        isSelected: s.isSelected,
      };
    }) ?? [],
    [fetchPatientWaypointSourcesResult?.data?.sources, onChangeSource],
  );

  const queries = React.useMemo(
    () => [
      ...appleHealthWaypoints.map((w, i) => {
        waypointIndexes[w?.id] = i;
        return {
          queryKey: [`${trackingUrls.waypoint.queryUrl}`, providerId, patientId, w.id, timeFrame],
          queryFn: fetchWaypointTracking(http.get),
          enabled: !!providerId && !!patientId,
        };
      }),
      {
        queryKey: [`${gamesUrls.stroopTimeSeries.queryUrl}`, providerId, patientId, timeFrame],
        queryFn: fetchGamesTracking(http.get),
        enabled: !!providerId && !!patientId,
      },
    ],
    [appleHealthWaypoints, providerId, patientId, timeFrame, http.get, waypointIndexes],
  );

  const fetchPatientWaypointTrackingResult = useQuery(
    [
      `${trackingUrls.waypointDetails.queryUrl}-${sourceId}-${timeFrame}`,
      providerId,
      patientId,
      '8a149fda-af93-48de-852b-bca0e5b50e02',
      timeFrame,
      sourceId,
    ],
    {
      queryFn: fetchWaypointTracking(http.get, true),
      enabled: !!providerId && !!patientId && !!sourceId && !!appleHealthWaypointMap,
    },
  );

  const queriesResults = useQueries(queries);

  const appleHealthSleepData = React.useMemo(() => {
    const data = (fetchPatientWaypointTrackingResult?.data as ListWaypointDetails)?.categoryAvgData;
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);

  const appleHealthSleepPreviousPeriodAvgData = React.useMemo(() => {
    const data = (fetchPatientWaypointTrackingResult?.data as ListWaypointDetails)
      ?.previousIntervalCategoryAvgData || [];
    return data;
  }, [fetchPatientWaypointTrackingResult?.data]);

  const isAppleHealthSleepDataLoading = React.useMemo(
    () => fetchPatientWaypointTrackingResult?.isFetching,
    [fetchPatientWaypointTrackingResult?.isFetching],
  );

  const appleHealthActivity = React.useMemo(
    () => queriesResults[waypointIndexes[appleHealthWaypointMap.activityAppleHealth]]
      ?.data as WaypointTrackingAPIResponse,
    [appleHealthWaypointMap.activityAppleHealth, queriesResults, waypointIndexes],
  );

  const appleHealthActivityData = React.useMemo(
    () => appleHealthActivity?.waypoints || [],
    [appleHealthActivity?.waypoints],
  );

  const appleHealthActivityAverageData = React.useMemo(
    () => appleHealthActivity?.averages || [],
    [appleHealthActivity?.averages],
  );

  const appleHealthActivityPreviousPeriodAverageData = React.useMemo(
    () => appleHealthActivity?.previousIntervalAverages || [],
    [appleHealthActivity?.previousIntervalAverages],
  );


  const appleHealthActivityGoals = React.useMemo(
    () => appleHealthActivity?.goals || [],
    [appleHealthActivity?.goals],
  );

  const appleHealthDaylightExposure = React.useMemo(
    () => queriesResults[waypointIndexes[appleHealthWaypointMap.daylightExposure]]
      ?.data as WaypointTrackingAPIResponse,
    [appleHealthWaypointMap.daylightExposure, queriesResults, waypointIndexes],
  );

  const appleHealthDaylightExposureData = React.useMemo(
    () => appleHealthDaylightExposure?.waypoints || [],
    [appleHealthDaylightExposure?.waypoints],
  );


  const numberOfCards = React.useMemo(() => {
    let count = 0;
    const dataAvailable = {} as Record<string, boolean>;

    if (sourceId) {
      count += 1;
    }

    if (appleHealthActivityData?.length > 0) {
      dataAvailable.activity = true;
    }
    count += appleHealthActivityData?.length > 0 || dataMap.activity ? 1 : 0;

    if (appleHealthDaylightExposureData?.length > 0) {
      dataAvailable.daylight = true;
    }
    count += appleHealthDaylightExposureData?.length > 0 || dataMap.daylight ? 1 : 0;

    return count;
  }, [
    appleHealthActivityData?.length,
    appleHealthDaylightExposureData?.length,
    dataMap.activity,
    dataMap.daylight,
    sourceId,
  ]);

  const barSize = React.useMemo(() => {
    const gridSize = numberOfCards === 3 ? 4 : 6;
    const updatedBarSize = barSizeMap[`${timeFrame as TimeFrameOptions}-${gridSize}`];

    return updatedBarSize;
  }, [numberOfCards, timeFrame]);

  const yAxisProps = getChartDateRanges(timeFrame as TimeFrameOptions) as {
    startTime: number;
    endTime: number;
    ticks: number[];
    isInDays: boolean;
  };
  const currentWaypointData = currentWaypoint.data ?? defaultWaypointTracking;
  const previousWaypointData = previousWaypoint.data ?? defaultWaypointTracking;
  const isAppleHealthSleepDataPresent = appleHealthSleepData?.length > 0;
  return (
    <Box width='100%' ml={2} mr={0}>
      {/* <Grid container spacing={2} mb={3} /> */}


      <Grid container spacing={2} justifyContent='center' mb={3} mt={3} textAlign='center'>
        {isAppleHealthSleepDataPresent && sourceId ? (
          <AppleHealthSleepChart
            timeFrame={timeFrame}
            averages={appleHealthSleepData}
            previousPeriodAverages={appleHealthSleepPreviousPeriodAvgData}
            setSourceId={onChangeSource}
            sourceId={sourceId}
            sourceOptions={sourceOptions}
            isSmallView
          />
        ) : (appleHealthActivityData?.length > 0 || dataMap.activity) ? (
          <Box ml={4} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
            <AppleHealthActivityChart
              chartData={appleHealthActivityData}
              chartGoals={appleHealthActivityGoals}
              yAxisProps={yAxisProps}
              barSize={barSize}
              averages={appleHealthActivityAverageData}
              previousPeriodAverages={appleHealthActivityPreviousPeriodAverageData}
              timeFrame={timeFrame}
              isSmallView
            />
          </Box>
        ) : currentWaypointData?.waypoints.length > 0 ? (
          <SleepChart
            currentDataArray={currentWaypointData.waypoints}
            previousDataArray={previousWaypointData.waypoints}
          />
        ) : (
          <Box
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            borderRadius={1.5}
            padding={1.5}
            maxWidth='lg'
            marginX='auto'
            mb={3}
          >
            <Typography variant='body1' mt={1} textAlign='center'>
              No data for this user
            </Typography>
          </Box>
        )}


      </Grid>
      {/* <WaypointTrackingSection
        patient={patient}
        timeFrame={timeFrame}
        currentWaypoints={currentWaypointData?.waypoints}
        previousWaypoints={previousWaypointData?.waypoints}
      /> */}
    </Box>
  );
}
