import { PracticeAddress } from 'types/Entitites';

export type GooglePhoneNumber = {
  number: string;
  countryCode: string;
  areaCode: string;
};

export function phoneNumberStringToGooglePhoneNumber(phoneNumber: string): GooglePhoneNumber {
  const numericValue = phoneNumber.replace(/\D/g, '');
  return {
    number: numericValue.substring(3),
    countryCode: '+1',
    areaCode: numericValue.substring(0, 3),
  };
}

export function googlePhoneNumberToPhoneNumberString(phoneNumber?: GooglePhoneNumber) {
  if (!phoneNumber?.countryCode || !phoneNumber?.areaCode || !phoneNumber?.number) {
    return '';
  }
  return `${phoneNumber.countryCode}${phoneNumber.areaCode}${phoneNumber.number}`;
}

/**
 * For PhoneNumberInput, the country code is not required
 */
export function googlePhoneNumberToPhoneNumberInputString(phoneNumber?: GooglePhoneNumber) {
  if (!phoneNumber?.countryCode || !phoneNumber?.areaCode || !phoneNumber?.number) {
    return '';
  }
  return `${phoneNumber.areaCode}${phoneNumber.number}`;
}

export function addressSerializar(address: Partial<PracticeAddress>) {
  return `${address.street} ${address.city} ${address.state} ${address.zip}`;
}
