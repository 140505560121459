import React from 'react';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { TableCell, TableRow, Typography } from '@mui/material';
import ResolveInvite from 'pages/Dashboard/components/ResolveInvite';

interface PatientComponentProps {
    patient: PatientObj;
}


function ErrorPatientComponent(props: PatientComponentProps) {
  const { patient } = props;
  const isNamePending = !patient?.name?.firstName;

  // Convert phone number to string or display fallback if missing
  const phoneNumberString = `Pending(${String(patient.phoneNumber?.number || 'No Number Available')})`;

  console.log('patient', patient.onboardingMetadata?.status);

  return (
    <TableRow
      sx={{
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <TableCell
        key={patient.id}
        scope='patient'
        sx={{
          color: isNamePending ? 'grey' : '#33739D',
          fontWeight: patient.hasRead ? 600 : 400,
        }}
        onClick={() => console.log('clicked')}
      >
        {isNamePending ? phoneNumberString : `${patient.name?.firstName} ${patient.name?.lastName}`}
      </TableCell>
      <TableCell sx={{ padding: '4px 8px' }}>{patient.dateAdded ? new Date(patient.dateAdded).toLocaleDateString('en-US') : ''}</TableCell>

      <TableCell
        color='grey'
      >
        {patient.onboardingMetadata?.status === 'INVITE_DELIVERY_FAILED' ? <ResolveInvite patient={patient} btnText='RESOLVE ERROR' /> : (
          <Typography sx={{
            color: 'grey',
          }}
          >
            {' '}
            No action available while invite in progress
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}


export default ErrorPatientComponent;
