import { WelcomeExplorerActionId } from 'utils/constants';
import { isDev } from 'utils/env';

const gateway = isDev ? '/api' : process.env.REACT_APP_GATEWAY_URL;

export const practiceUrls = {
  listPractices: {
    queryUrl: 'list-practices',
    apiUrls: (providerId: string) => ({
      list: `${gateway}/provider/${providerId}/practice/list`,
    }),
  },
};

export const trackingUrls = {
  moodList: {
    queryUrl: 'mood-tracking-list',
    apiUrls: (
      providerId: string,
      patientId: string,
      startDate: string,
      endDate: string,
      mood: string,
    ) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/tracking/moods/${mood}?start-date=${startDate}&end-date=${endDate}`,
    }),
  },
  moodAverage: {
    queryUrl: 'mood-tracking-average',
    apiUrls: (providerId: string, patientId: string, startDate: string, endDate: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/mood?start-date=${startDate}&end-date=${endDate}&operation=TRACKING_DATA_OPERATION_AVERAGE`,
    }),
  },
  waypoints: {
    queryUrl: 'waypoint-tracking',
    apiUrls: (providerId: string, patientId: string, startDate: string, endDate: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/tracking/waypoints?start-date=${startDate}&end-date=${endDate}`,
    }),
  },
  waypointAverage: {
    queryUrl: 'waypoint-tracking-average',
    apiUrls: (providerId: string, patientId: string, startDate: string, endDate: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/waypoint?start-date=${startDate}&end-date=${endDate}&operation=TRACKING_DATA_OPERATION_AVERAGE`,
    }),
  },
  waypoint: {
    queryUrl: 'waypoint-tracking',
    apiUrls: (
      providerId: string,
      patientId: string,
      waypointId: string,
      startDate?: string,
      endDate?: string,
      duration?: string,
    ) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/tracking/waypoints/${waypointId}?start-date=${startDate}&end-date=${endDate}&duration=${duration}`,
    }),
  },
  waypointDetails: {
    queryUrl: 'waypoint-details',
    apiUrls: (
      providerId: string,
      patientId: string,
      waypointId: string,
      startDate?: string,
      endDate?: string,
      duration?: string,
      sourceId?: string,
    ) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/tracking/waypoint/details/${waypointId}?start-date=${startDate}&end-date=${endDate}&duration=${duration}&source-id=${sourceId}`,
    }),
  },
  waypointSources: {
    apiUrls: (
      providerId: string,
      patientId: string,
    ) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/tracking/waypoint/sources`,
    }),
    queryUrl: 'waypoint-sources',
  },
};

export const gamesUrls = {
  stroopTimeSeries: {
    queryUrl: 'stroop-time-series',
    apiUrls: (
      providerId: string,
      patientId: string,
      startDate: string,
      endDate: string,
      duration?: string,
    ) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/games/2f8cfb5b-7d51-4704-9b32-ac6e6726dd22/timeSeries?start-date=${startDate}&end-date=${endDate}&duration=${duration}`,
    }),
  },
};

export const whoIam = {
  categories: {
    queryUrl: 'who-i-am-categories',
    apiUrl: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/whoiam/categories`,
    }),
  },
  categoryResults: {
    queryUrl: 'who-i-am-category-result',
    apiUrls: (providerId: string, patientId: string, categoryId: string) => ({
      getCategoryResult: `${gateway}/provider/${providerId}/patient/${patientId}/whoiam/categories/${categoryId}/results?limit=2&page=0`,
    }),
  },
  exportData: {
    queryUrl: 'who-i-am-export-data',
    apiUrl: {
      getData: `${gateway}/platform/whoiam/export`,
    },
  },
};

export const patientExport = {
  queryUrl: 'patient-export-data',
  apiUrl: (providerId: string, patientId: string, asPDF?: boolean) => ({
    getData: `${gateway}/provider/${providerId}/patient/${patientId}/export${asPDF ? '?fileType=pdf' : ''}`,
  }),
};

export const patientUrls = {
  createPatient: {
    queryUrl: 'create-patient',
    apiUrls: (providerId: string) => ({
      create: `${gateway}/provider/${providerId}/patient/create`,
    }),
  },
  invitePatients: {
    queryUrl: 'invite-patients',
    apiUrls: (providerId: string) => ({
      create: `${gateway}/v2/provider/${providerId}/patient/create`,
    }),
  },
  listPatients: {
    queryUrl: 'list-patients',
    apiUrls: (providerId: string, status?: string, searchQuery?: string, sortDirection?: 'asc' | 'desc', sortBy?: string, offset?:number, limit?:number) => {
      const queryParams = new URLSearchParams();

      if (status) queryParams.append('status', status);
      if (searchQuery) queryParams.append('name', searchQuery);
      if (sortBy) queryParams.append('sortBy', sortBy ?? '');
      if (sortDirection) queryParams.append('sortDirection', sortDirection);
      if (offset) queryParams.append('offset', offset.toString());
      if (limit) queryParams.append('limit', limit.toString());

      return {
        list: `${gateway}/v2/provider/${providerId}/patient/list${queryParams.toString() ? `?${queryParams.toString()}` : ''}`,
      };
    },
  },
  patientsCount: {
    queryUrl: 'patients-count',
    apiUrls: (providerId: string) => ({
      count: `${gateway}/v2/provider/${providerId}/patient/status/count`,
    }),
  },
  resendPatientInvite: {
    queryUrl: 'resend-patient-invite',
    apiUrls: (providerId: string) => ({
      create: `${gateway}/v2/provider/${providerId}/patient/create/retry`,
    }),
  },
  patientDetails: {
    queryUrl: 'patient-details',
    apiUrls: (providerId: string, patientId: string) => ({
      details: `${gateway}/provider/${providerId}/patient/${patientId}`,
    }),
  },
  patientHealthRecordStatus: {
    queryUrl: 'patient-health-record-status',
    apiUrls: (providerId: string, patientId: string) => ({
      status: `${gateway}/provider/${providerId}/patient/${patientId}/health-records/status`,
    }),
  },
};

export const medicationsUrls = {
  listMedications: {
    queryUrl: 'list-medications',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/medications/list`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/medications/list?current=true`,
    }),
  },
};

export const diagnosisUrls = {
  listDiagnosis: {
    queryUrl: 'list-diagnosis',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/diagnosis/list`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/diagnosis/list?current=true`,
    }),
  },
};

export const familyHistoryUrls = {
  listFamilyHistory: {
    queryUrl: 'list-family-history',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/family-history/list`,
    }),
  },
};

export const symptomsUrls = {
  listSymptoms: {
    queryUrl: 'list-symptoms',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/symptoms/list`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/symptoms/list?current=true`,
    }),
  },
};

export const hospitalizationsUrls = {
  listHospitalizations: {
    queryUrl: 'list-hospitalizations',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/hospitalizations/list`,
    }),
  },
};

export const labsUrls = {
  listLabs: {
    queryUrl: 'list-labs',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/labs/list`,
    }),
  },
  labReport: {
    queryUrl: 'labs-report',
    apiUrls: (providerId: string, patientId: string, labId: string) => ({
      report: `${gateway}/provider/${providerId}/patient/${patientId}/labs/${labId}/pdf`,
    }),
  },
};

export const allergiesUrls = {
  listAllergies: {
    queryUrl: 'list-allergies',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/allergies/list`,
    }),
  },
};

export const patientProviderUrls = {
  listPatientProviders: {
    queryUrl: 'list-patient-providers',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/providers/list`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/providers/list?current=true`,
    }),
  },
  listPatientPrimaryCareProviders: {
    queryUrl: 'list-patient-primary-care-providers',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/primary-care-provider`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/primary-care-provider?current=true`,
    }),
  },
  listPatientMentalHealthProviders: {
    queryUrl: 'list-patient-mental-health-providers',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/mentalhealth-care-provider`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/mentalhealth-care-provider?current=true`,
    }),
  },
};

export const lifestyleUrls = {
  listLifestyle: {
    queryUrl: 'list-lifestyle',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/life-style/list`,
      listCurrent: `${gateway}/provider/${providerId}/patient/${patientId}/life-style/list?current=true`,
    }),
  },
};

export const backgroundsUrls = {
  listBackgrounds: {
    queryUrl: 'list-backgrounds',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/backgrounds/list?current=true`,
    }),
  },
};

export const wayPointsUrls = {
  listWayPoints: {
    queryUrl: 'list-waypoint',
    apiUrls: (providerId: string) => ({
      list: `${gateway}/provider/${providerId}/waypoint/list`,
    }),
  },
  listPatientWaypoints: {
    queryUrl: 'patient-waypoints',
    apiUrls: (patientId: string) => ({
      list: `${gateway}/patient/${patientId}/waypoint`,
    }),
  },
  createWayPoint: {
    queryUrl: 'create-waypoint',
    apiUrls: (providerId: string) => ({
      create: `${gateway}/provider/${providerId}/waypoint/create`,
    }),
  },
};

export const wayPointSuggestionUrls = {
  listWaypointSuggestions: {
    queryUrl: 'waypoint-suggestions',
    apiUrls: (providerId: string, patientId: string) => ({
      list: `${gateway}/provider/${providerId}/patient/${patientId}/waypoint/suggestion`,
    }),
  },
  createWaypointSuggestions: {
    queryUrl: 'create-waypoint-suggestions',
    apiUrls: (providerId: string, patientId: string) => ({
      create: `${gateway}/provider/${providerId}/patient/${patientId}/waypoint/suggestion`,
    }),
  },
  deleteWaypointSuggestions: {
    queryUrl: 'revoke-waypoint-suggestions',
    apiUrls: (providerId: string, patientId: string) => ({
      delete: `${gateway}/provider/${providerId}/patient/${patientId}/waypoint/suggestion`,
    }),
  },
};

export const providerSignUpUrls = {
  createProvider: {
    queryUrl: 'create-provider',
    apiUrls: () => ({
      create: `${gateway}/provider-request/create`,
    }),
  },
  listProviderRequest: {
    queryUrl: 'list-provider-request',
    apiUrls: () => ({
      list: `${gateway}/provider-request/list`,
    }),
  },
};

export const providerRequestActionUrls = {
  approveProviderRequest: {
    queryUrl: 'approve-provider-request',
    apiUrls: (id: string) => ({
      approveProviderRequest: `${gateway}/provider-request/${id}/approve`,
    }),
  },
};

export const providers = {
  practiceProvidersList: {
    queryUrl: 'practice-providers-list',
    apiUrl: (practiceId: string, designations?: string) => `${gateway}/practice/${practiceId}/list-providers?designations=${designations}`,
  },
  providerAssociationList: {
    queryUrl: 'providers-associations-list',
    apiUrl: (providerId: string) => `${gateway}/staff/${providerId}/list-association`,
  },
  metadata: {
    queryUrl: 'user-metadata',
    apiUrl: () => `${gateway}/users/user-metadata`,
  },
  postMetadata: {
    queryUrl: 'user-metadata-create',
    apiUrl: () => `${gateway}/users/user-metadata-create`,
  },
};

export const providerConsentUrls = {
  consent: {
    queryUrl: 'provider-consent',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/consent`,
  },
  giveConsent: {
    queryUrl: 'give-provider-consent',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/consent`,
  },
};

export const rewardsUrls = {
  rewardPoints: {
    queryUrl: 'get-provider-reward-points',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/strapi/clinicians?filters[providerId][$eq]=${providerId}`,
  },
  rewards: {
    queryUrl: 'get-all-rewards',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/strapi/rewards?sort[0]=PointsRequired:asc`,
  },
  explorerActions: {
    queryUrl: 'get-all-explorer-actions',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/strapi/explorer-actions?filters[identifier][$ne]=${WelcomeExplorerActionId}&sort[0]=Points:asc`,
  },
  transactions: {
    queryUrl: 'get-provider-transactions',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/strapi/transactions?populate=*&sort[0]=date:desc&filters[clinician][providerId][$eq]=${providerId}`,
  },
  sendReferral: {
    queryUrl: 'send-referral-eamil',
    apiUrl: (providerId: string) => `${gateway}/provider/${providerId}/referral/send`,
  },
};

export const providerPracticesUrls = {
  listProviderPractices: {
    queryUrl: 'list-provider-practices',
    apiUrls: () => ({
      list: `${gateway}/provider/list/provider-practices`,
    }),
  },
};

export const scalesManifestUrls = {
  listScalesManifest: {
    queryUrl: 'list-scales-manifest',
    apiUrl: (
      categoryId: string,
      providerId: string,
      patientId: string,
      startDate: string,
      endData: string,
      attemptId: string,
      page: number,
      size: number,
      billingAdminId: string,
    ) => {
      const apiUrlParams = {
        category_id: categoryId,
        'provider-id': providerId,
        'patient-id': patientId,
        'start-date': startDate,
        'end-date': endData,
        attemptId,
        offset: page,
        limit: size,
      };

      const apiUrlParamsString = Object.entries(apiUrlParams)
        .filter(([_, value]) => !!value || value === 0)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

      const listUrl = `${gateway}/provider/${billingAdminId}/whoiam/categories/results/details?${apiUrlParamsString}`; return ({

        list: listUrl,
      });
    },
  },
  attemptDetails: {
    queryUrl: 'attempt-details',
    apiUrl: (categoryId: string, providerId: string, attemptId?: string) => ({
      details: `${gateway}/provider/${providerId}/whoiam/categories/${categoryId}/results/attempt/details${attemptId ? `?attempt_id=${attemptId}` : ''}`,
    }),
  },
  addNotes: {
    queryUrl: 'add-notes',
    apiUrl: (categoryId: string, providerId: string, attemptId: string) => ({
      add: `${gateway}/provider/${providerId}/whoiam/categories/${categoryId}/attempt/${attemptId}`,
    }),
  },
  sendScaleSuggestions: {
    queryUrl: 'send-scale-suggestions',
    apiUrl: (patientId: string, providerId: string) => ({
      send: `${gateway}/provider/${providerId}/whoiam/categories/suggestions?patient-id=${patientId}`,
    }),
  },
};
