import React from 'react';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { Chip, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CheckCircle } from 'assets/CheckCircleFilled.svg';
import { ReactComponent as TrendingUp } from 'assets/TrendingUpFilled.svg';
import { ReactComponent as TrendingDown } from 'assets/TrendingDownFilled.svg';
import { ReactComponent as NoData } from 'assets/NotEnoughData.svg';
import { ReactComponent as TrendingFlat } from 'assets/TrendingFlatFilled.svg';
import * as spaUrls from 'utils/spaUrls';
import { useNavigate } from 'react-router-dom';
import ExpandedRowView from 'pages/Dashboard/components/ExpandedView';
import { ExpandMoreOutlined } from '@mui/icons-material';


interface ReadyPatientComponentProps {
    patient: PatientObj;
    onAccordionChange: (patientId:string) => void;
    expanded: boolean;
}
function GetThirtyDayProgress({ thirtyDayProgress }:{thirtyDayProgress:PatientObj['thirtyDayProgress']}) {
  switch (thirtyDayProgress) {
    case 'UP':
      return <TrendingUp />;
    case 'DOWN':
      return <TrendingDown />;
    case 'NEUTRAL':
      return <TrendingFlat />;
    default:
      return <NoData />;
  }
}
function GetHealthRecordStatus({ healthRecordStatus }:{healthRecordStatus:PatientObj['healthRecordStatus']}) {
  switch (healthRecordStatus) {
    case 'IN_PROGRESS':
      return (
        <Chip
          label='In progress'
          sx={{
            backgroundColor: '#E9F9EA',
          }}
        />
      );
    case 'PRESENT':
      return <CheckCircle />;
    default:
      return (
        <Chip
          label='In progress'
          sx={{
            backgroundColor: '#E9F9EA',
          }}
        />
      );
  }
}
function ToolTipComponent() {
  return (
    <Typography sx={{ color: 'black' }}>
      An improvement of 2 or more in any mood vector, without significant declines in others
      , compared to the prior 30 days
    </Typography>
  );
}
function GetBehavioralDataStatus({ behavioralDataStatus }:{behavioralDataStatus:PatientObj['behavioralDataStatus']}) {
  switch (behavioralDataStatus) {
    case 'PRESENT':
      return <CheckCircle />;
    case 'NO_DATA':
      return (
        <Chip
          label='no data'
          sx={{
            backgroundColor: '#E9E9E9',
          }}
        />
      );
    case 'SIGNUP_PENDING':
      return (
        <Chip
          label='signup pending'
          sx={{
            backgroundColor: '#E9E9E9',
          }}
        />
      );
    default:
      return <Chip label='In progress' />;
  }
}

function ReadyPatientComponent(props: ReadyPatientComponentProps) {
  const { patient, onAccordionChange, expanded } = props;
  const navigate = useNavigate();

  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: '#f2f2f2',
          },
          height: '20px', // Adjust the height of the row
        }}
      >
        <TableCell
          component='th'
          scope='patient'
          sx={{
            color: '#33739D',
            fontWeight: patient.hasRead ? 600 : 400,
            padding: '4px 8px',
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate(spaUrls.mainNav.patientDetail(patient?.id));
          }}
        >
          {`${patient.name?.firstName} ${patient.name?.lastName}`}
        </TableCell>
        <TableCell sx={{ padding: '4px 8px', textAlign: 'center' }}>{patient.dateAdded ? new Date(patient.dateAdded).toLocaleDateString('en-US') : ''}</TableCell>
        <TableCell sx={{ padding: '4px 8px', textAlign: 'center' }}>
          <GetHealthRecordStatus healthRecordStatus={patient.healthRecordStatus} />
        </TableCell>
        <TableCell sx={{ padding: '4px 8px', textAlign: 'center' }}>
          <GetBehavioralDataStatus behavioralDataStatus={patient.behavioralDataStatus} />
        </TableCell>
        <TableCell sx={{ padding: '4px 8px', textAlign: 'left' }}>{patient.patientActivity ? new Date(patient.patientActivity).toLocaleDateString('en-US') : ''}</TableCell>
        <Tooltip
          title={<ToolTipComponent />}
          sx={{

          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: 'white', // Tooltip background color
                color: 'black', // Tooltip text color
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Optional shadow
                borderRadius: '4px', // Rounded corners
                maxWidth: '220px', // Optional: limit tooltip width
                padding: '8px', // Padding inside the tooltip
              },
            },

          }}
        >
          <TableCell>

            {/* Use a span or div wrapper around the content */}
            <GetThirtyDayProgress thirtyDayProgress={patient.thirtyDayProgress} />
            <ExpandMoreOutlined onClick={() => {
              onAccordionChange(patient.id ?? '');
            }}
            />
          </TableCell>
        </Tooltip>
        {/* <ExpandedRowView id={patient.id ?? ''} /> */}
      </TableRow>
      {expanded ? (
        <ExpandedRowView id={patient.id ?? ''} />
      ) : null}
    </>
  );
}

export default ReadyPatientComponent;
