import * as React from 'react';
import { Grid, Skeleton, TableCell, Typography } from '@mui/material';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { whoIam } from 'utils/apiUrls';
import { NotSpecified } from 'utils/constants';
import { DiscColors, DiscIdentifyingTerms } from 'pages/Dashboard/utils/whoiamUtils';
import { DISCKeys } from 'pages/Dashboard/types/whoiam.types';

interface PatientDISCInfoProps {
  id: string;
}

export default function PatientDISCInfo({ id }: PatientDISCInfoProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patienId } = useParams();

  const discInfo = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patienId, id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
    },
  );

  if (discInfo.isFetching) {
    return <TableCell><Skeleton /></TableCell>;
  }

  function combineTerms(resultDescription: string) {
    if (!resultDescription) {
      return '';
    }

    const termLetters: DISCKeys[] = resultDescription.split('/') as DISCKeys[];

    return termLetters
      .map((letter) => DiscIdentifyingTerms[letter])
      .filter(Boolean)
      .join(', ')
      .trim();
  }


  const result = discInfo?.data?.results[0];
  const metaInfo = result && JSON.parse(result.metaInfo as string);

  return (
    <TableCell>
      {result ? (
        <Grid container>
          <Grid item display='flex' xs={12} gap={1}>
            <Typography color={DiscColors.D}>(D)</Typography>
            <Typography>{`${metaInfo.percents.D}%`}</Typography>
            <Typography color={DiscColors.I}>(I)</Typography>
            <Typography>{`${metaInfo.percents.I}%`}</Typography>
            <Typography color={DiscColors.S}>(S)</Typography>
            <Typography>{`${metaInfo.percents.S}%`}</Typography>
            <Typography color={DiscColors.C}>(C)</Typography>
            <Typography>{`${metaInfo.percents.C}%`}</Typography>
          </Grid>
          <Grid item>
            <Typography>{combineTerms(result?.description)}</Typography>
          </Grid>
        </Grid>
      ) : <Typography>{NotSpecified}</Typography>}

    </TableCell>
  );
}
