import { ArrowDropDown, ArrowDropUp, Category } from '@mui/icons-material';
import { Box, Button, Divider, Grid, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import AppleHealthIcon from 'components/Common/AppleHealthIcon';
import { useHttp } from 'hooks/use-fetch';
import CustomAppleHealthSleepChart from 'pages/Dashboard/components/AppleHealthSleepView';
import GaugeChart from 'pages/Dashboard/components/GaugeChart';
import PatientRatingScales from 'pages/Dashboard/components/PatientRatingScales';
import SleepChart from 'pages/Dashboard/components/SelfReportedSleepChart';
import { fetchMoodAverage } from 'pages/Dashboard/services/tracking.services';
import { fetchWhoIamCategories } from 'pages/Dashboard/services/whoiam.services';
import { MoodColorType } from 'pages/Dashboard/types/moods.types';
import { CategoryType } from 'pages/Dashboard/types/whoiam.types';
import {
  defaultMoodTracking,
  getGaugeColor,
  getGaugeData,
  getMoodOptions,
  getTimeFrameInterval,
  TimeFrameOptions,
} from 'pages/Dashboard/utils/trackingUtils';
import React from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { trackingUrls, whoIam } from 'utils/apiUrls';
import { MixpanelEventName } from 'utils/constants';
import { Logger } from 'utils/logger';
import * as spaUrls from 'utils/spaUrls';
import { trackMixpanelEvent } from 'utils/utilMethods';
import theme from 'theme';
import PatientTrackingSummary from 'pages/Dashboard/PatientTrackingSummary';


const MoodColors: MoodColorType = {
  Happiness: { positive: 'green', negative: 'red' },
  Motivation: { positive: 'green', negative: 'red' },
  Anxiousness: { positive: 'red', negative: 'green' },
};
function Comparison({ moodDifference, cardData }: { moodDifference: number; cardData: any }) {
  const isPositive = moodDifference > 0;
  const isZero = moodDifference === 0;

  if (isZero) {
    return (
      <Typography textAlign='center' color='gray' fontWeight='400'>
        no change
      </Typography>
    );
  }
  const color = isPositive
    ? MoodColors[cardData.label].positive
    : MoodColors[cardData.label].negative;
  return (
    <>
      {isPositive ? (
        <ArrowDropUp sx={{ mt: '2px', color: `${color}` }} />
      ) : (
        <ArrowDropDown sx={{ mt: '2px', color: `${color}` }} />
      )}
      <Typography textAlign='center' color={color} fontWeight='500'>
        {isPositive ? `${moodDifference}` : moodDifference}
      </Typography>
    </>
  );
}
function ExpandedRowView({ id }: { id: string }) {
  const { http, providerId } = useHttp();
  const navigate = useNavigate();

  const currentMoodAverage = useQuery(
    [
      `${trackingUrls.moodAverage.queryUrl}`,
      providerId,
      id,
      TimeFrameOptions.LAST_30_DAYS,
      'current',
    ],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: !!id,
    },
  );
  const [sourceId, setSourceId] = React.useState<string>('');

  const onChangeSource = React.useCallback((source: string) => {
    trackMixpanelEvent(MixpanelEventName.AH_SLEEP_CHART_SOURCE_CHANGED, {
      'patient id': id ?? '',
    }, true);
    setSourceId(source);
  }, [id]);


  const previousMoodAverage = useQuery(
    [
      `${trackingUrls.moodAverage.queryUrl}`,
      providerId,
      id,
      TimeFrameOptions.LAST_30_DAYS,
      'previous',
    ],
    {
      queryFn: fetchMoodAverage(http.get),
      onError: () => onError('Error fetching patient mood'),
      enabled: !currentMoodAverage.isFetching && !!id,
    },
  );
  const whoIAmCategories = useQuery([whoIam.categories.queryUrl, providerId, id], {
    queryFn: fetchWhoIamCategories(http.get),
  });
  const onError = (message: string) => {
    Logger.error(message);
    // setToastProps({
    //   message,
    //   open: true,
    //   type: ToastType.error,
    // });
  };
  const currentMoodData = currentMoodAverage.data ?? defaultMoodTracking;
  const previousMoodData = previousMoodAverage.data ?? defaultMoodTracking;
  const moodOptions = getMoodOptions(currentMoodData.moods, previousMoodData.moods);
  const timeFrame = TimeFrameOptions.LAST_30_DAYS;
  const interval = getTimeFrameInterval(timeFrame);
  const moodDifference = console.log(`currentMoodData: ${JSON.stringify(moodOptions)}`);
  const applicableRatingScales = React.useMemo(
    () => whoIAmCategories?.data?.categories?.filter?.(
      (category) => category.type === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE,
    ),
    [whoIAmCategories],
  );
  const navigateToPatientDetailsPage = () => {
    navigate(spaUrls.mainNav.patientDetail(id));
  };


  return (
    <TableRow>
      <TableCell colSpan={7} sx={{ mt: 20 }}>
        <Grid container spacing={6}>
          <Grid item xs={4}>
            <Typography variant='h6'>Mood </Typography>
            <Typography variant='body2' sx={{ color: 'gray' }}>
              (30-day averages)
            </Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <Grid container spacing={2} wrap='wrap'>
              {moodOptions.map((cardData) => (
                <Grid item xs={12} sm={6} md={4} key={cardData.label}>
                  {/* Each chart is placed in its own Grid item to display in a row */}
                  <Typography textAlign='center'>{cardData.label}</Typography>
                  <GaugeChart
                    color={getGaugeColor(cardData.mood, cardData.currentValue)}
                    data={getGaugeData(cardData.currentValue)}
                    value={cardData.currentValue.toString()}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: -3, ml: 0 }}>
                    <Comparison
                      moodDifference={cardData.currentValue - cardData.previousValue}
                      cardData={cardData}
                    />
                  </Box>

                </Grid>
              ))}
              <Typography
                textAlign='center'
                fontWeight='400'
                sx={{
                  color: 'gray',
                  marginTop: 4,
                }}
              >
                change vs. prior 30 day average
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={4}>
            <Typography variant='h6'>Waypoints</Typography>
            <Typography variant='body2' sx={{ color: 'gray' }}>
              (30-day averages)
            </Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <Grid container spacing={4} mt={2}>

              <PatientTrackingSummary patientId={id} />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Typography variant='h6'>Profile</Typography>
            <Typography variant='body2' sx={{ color: 'gray' }}>
              (Past 30 days)
            </Typography>
            <Divider sx={{ margin: '20px 0' }} />
            <PatientRatingScales ratingScales={applicableRatingScales ?? []} id={id} />
          </Grid>
        </Grid>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', marginTop: 3 }} onClick={navigateToPatientDetailsPage}>
          <Button variant='contained' sx={{ marginTop: '20px' }}>
            View More
          </Button>
        </Box>
      </TableCell>
    </TableRow>
  );
}

// Memoize the component
const ExpandedRowViewMemo = React.memo(ExpandedRowView);

export default ExpandedRowViewMemo;
