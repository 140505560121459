import { Box, Grid, Typography } from '@mui/material';
import { useHttp } from 'hooks/use-fetch';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { CategoryResult, Scale } from 'pages/Dashboard/types/whoiam.types';
import React from 'react';
import { useQuery } from 'react-query';
import { whoIam } from 'utils/apiUrls';
import { MixpanelEventName, NotSpecified } from 'utils/constants';
import { trackMixpanelEvent } from 'utils/utilMethods';
import theme from 'theme';
import { format } from 'date-fns';
import ScaleTitle from 'pages/Dashboard/components/ScaleTitle';

const comparison = (result: CategoryResult[], reversed?: boolean) => {
  const diff = result[0].score - result[1].score;
  const SuccessIcon = reversed ? ArrowDropUpIcon : ArrowDropDownIcon;
  const ErrorIcon = reversed ? ArrowDropDownIcon : ArrowDropUpIcon;

  if (reversed ? diff <= 0 : diff >= 0) {
    return (
      <Box display='flex'>
        <ErrorIcon color='error' />
        <Typography color='red'>
          {reversed ? '' : '+'}
          {diff}
        </Typography>
      </Box>
    );
  }
  else {
    return (
      <Box display='flex'>
        <SuccessIcon color='success' />
        <Typography color='green'>
          {reversed ? '+' : ''}
          {diff}
        </Typography>
      </Box>
    );
  }
};

function ScaleResult({
  id,
  patientId,
  name,
  setSelectedScale,
}: {
  id: string;
  patientId: string;
  name: string;
  setSelectedScale: React.Dispatch<React.SetStateAction<Scale>>;
}) {
  const { http, providerId } = useHttp();
  console.log(name);
  const scaleResult = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patientId, id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
      enabled: !!id,
    },
  );
  const scaleResultData = scaleResult.data?.results ?? [];
  const onScaleClickHandler = (categoryId: string) => () => {
    const selectedScaleDetails = {
      categoryId,
      results: scaleResultData,
    };

    setSelectedScale({
      ...(selectedScaleDetails.results?.[0] || ({} as CategoryResult)),
      categoryId: selectedScaleDetails.categoryId,
      scale: name,
    });

    trackMixpanelEvent(MixpanelEventName.SCALE_CLICKED, {
      scale: name,
    });
  };
  return name ? (
    <Grid item xs={4}>
      <Box display='flex' flexDirection='column' alignItems='flex-start'>
        <ScaleTitle
          scaleName={name}
          isLink={!!scaleResultData?.length}
          onScaleClickHandler={onScaleClickHandler}
        />
        {scaleResultData?.length ? (
          <>
            <Box display='flex'>
              <Typography>{scaleResultData?.[0]?.score}</Typography>
              {scaleResult && scaleResultData.length > 1 && comparison(scaleResultData)}
            </Box>
            <Typography style={{ whiteSpace: 'pre-line', fontWeight: 500 }}>{scaleResultData?.[0]?.description}</Typography>

            <Typography color={theme.custom.colors.lightTextSecondary}>
              {`(${format(new Date(scaleResultData?.[0]?.attemptedAt || ''), 'M/d/yyyy')})`}
            </Typography>
          </>
        ) : (
          <Typography>{NotSpecified}</Typography>
        )}
      </Box>
    </Grid>
  ) : null;
}

export default ScaleResult;
