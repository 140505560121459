import { WaypointTracking } from 'pages/Dashboard/types/waypoints.types';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Define the types for your data structure
interface Waypoint {
  id: string;
  name: string;
  value: number;
}


// Helper function to categorize the values into "Less", "Typical", and "More"
const categorizeSleep = (value: number): 'Less' | 'Typical' | 'More' => {
  if (value <= 10) return 'Less';
  if (value > 10 && value <= 15) return 'Typical';
  return 'More';
};

// Function to count the categories in a given array
const countSleepCategories = (dataArray: WaypointTracking[]) => {
  const counts = { Less: 0, Typical: 0, More: 0 };

  dataArray.forEach((data) => {
    if (data.waypoint.name === 'sleep') {
      const category = categorizeSleep(data.value);
      counts[category] += 1;
    }
  });

  return counts;
};

// Props type for the chart component
interface SleepChartProps {
  previousDataArray: WaypointTracking[];
  currentDataArray: WaypointTracking[];
}

// eslint-disable-next-line react/function-component-definition
const SleepChart: React.FC<SleepChartProps> = ({ previousDataArray, currentDataArray }) => {
  // Get counts for previous and current data
  const previousCounts = countSleepCategories(previousDataArray);
  const currentCounts = countSleepCategories(currentDataArray);

  // Create the data for the chart
  const sleepChartData = [
    {
      name: 'Less',
      past30Days: currentCounts.Less,
      prior30Days: previousCounts.Less,
    },
    {
      name: 'Typical',
      past30Days: currentCounts.Typical,
      prior30Days: previousCounts.Typical,
    },
    {
      name: 'More',
      past30Days: currentCounts.More,
      prior30Days: previousCounts.More,
    },
  ];

  return (
    <div style={{ width: '100%', height: 400, display: 'flex', justifyContent: 'center' }}>
      {' '}
      {/* Ensure parent container has width and height */}
      <BarChart
        width={400} // Explicit width
        height={300} // Explicit height
        data={sleepChartData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis label={{ value: 'Times Logged', angle: -90, position: 'insideLeft' }} />
        <Tooltip />
        <Legend />
        <Bar dataKey='past30Days' fill='#004477' name='Past 30 days' />
        <Bar dataKey='prior30Days' fill='#66A2D1' name='Prior 30 days' />
      </BarChart>
    </div>
  );
};

export default SleepChart;
