import * as React from 'react';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Table,
  TableRow,
  TableCell,
  tableCellClasses,
  Typography,
  Tooltip,
  Box,
  TableBody,
  Button,
} from '@mui/material';
import {
  CategoriesResponse,
  Category,
  CategoryName,
  CategoryType,
} from 'pages/Dashboard/types/whoiam.types';
import PatientDISCInfo from 'pages/Dashboard/components/PatientDISCInfo';
import PatientRatingScales from 'pages/Dashboard/components/PatientRatingScales';
import NoBackgroundData from 'pages/Dashboard/components/NoBackgroundData';
import PatientBackgroundInfo from 'pages/Dashboard/components/PatientBackgroundInfo';
import PatientQualityOfLife from 'pages/Dashboard/components/PatientQualityOfLife';
import PatientCoreValues from 'pages/Dashboard/components/PatientCoreValues';
import PatientLocusOfControl from 'pages/Dashboard/components/PatientLocusOfControl';
import { CategoryTooltipText } from 'pages/Dashboard/utils/whoiamUtils';
import { UseQueryResult } from 'react-query';
import { PatientBackgroundAPIResponse } from 'pages/Dashboard/types/background.types';
import { Notifications } from '@mui/icons-material';
import { ConfigureScalesDialog } from 'pages/Dashboard/components/ConfigureScalesDialog';
import { useHttp } from 'hooks/use-fetch';
import PatientGenericTestResult from 'pages/Dashboard/components/PatientGenericTestResult';

interface PatientProfileProps {
  categoriesData: CategoriesResponse;
  backgrounds: UseQueryResult<PatientBackgroundAPIResponse>;
}

export default function PatientProfile({ categoriesData, backgrounds }: PatientProfileProps) {
  const { isSuggestScalesEnabled } = useHttp();
  const { data: backgroundResponse } = backgrounds;

  const [isScalesSuggestionDialogOpen, setIsScalesSuggestionDialogOpen] = React.useState(false);
  const [categoryLookup, setCategoryLookup] = React.useState<{ [key: string]: Category }>({});
  const [allCategories, setAllCategories] = React.useState<Category[]>([]);

  React.useEffect(() => {
    const { categories } = categoriesData;
    if (!categories) {
      return;
    }
    setAllCategories(categories);
    const tempLookup = categoryLookup;
    categories.forEach((category) => {
      tempLookup[category.name] = category;
    });

    setCategoryLookup(tempLookup);
  }, [categoriesData.categories, categoriesData, categoryLookup]);

  const rowHead = (title: string, tooltipText = '') => (
    <TableCell sx={{ mr: '24px' }}>
      <Box display='flex' gap={1}>
        <Typography>{title}</Typography>
        {tooltipText && (
          <Tooltip title={<Typography>{tooltipText}</Typography>}>
            <HelpOutlineIcon fontSize='small' color='inherit' />
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );

  const renderCategoryData = (category: string) => {
    switch (category) {
      case CategoryName.WIM_CATEGORY_NAME_QUALITY_OF_LIFE:
        return <PatientQualityOfLife id={categoryLookup[category].id} />;

      case CategoryName.WIM_CATEGORY_NAME_CORE_VALUES:
        return <PatientCoreValues id={categoryLookup[category].id} />;

      case CategoryName.WIM_CATEGORY_NAME_LOCUS_OF_CONTROL:
        return <PatientLocusOfControl id={categoryLookup[category].id} />;

      case CategoryName.WIM_CATEGORY_NAME_DISC:
        return <PatientDISCInfo id={categoryLookup[category].id} />;
      default:
        return <PatientGenericTestResult id={categoryLookup[category].id} />;
    }

    return null;
  };
  const configuringScales = React.useMemo(
    () => allCategories.filter(
      (category) => category.type === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE,
    ),
    [allCategories],
  );
  const applicableRatingScales = React.useMemo(
    () => categoriesData?.categories?.filter?.(
      (category) => category.type === CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE,
    ),
    [categoriesData],
  );
  console.log(configuringScales, applicableRatingScales);

  return (
    <Box>
      <ConfigureScalesDialog
        open={isScalesSuggestionDialogOpen}
        scales={configuringScales}
        onClose={() => setIsScalesSuggestionDialogOpen(false)}
      />
      {isSuggestScalesEnabled && (
        <Box display='flex' justifyContent='flex-end' mb={2}>
          <Button
            variant='outlined'
            startIcon={<Notifications />}
            onClick={() => setIsScalesSuggestionDialogOpen(true)}
          >
            SUGGEST SCALES
          </Button>
        </Box>
      )}
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            padding: '10px',
          },
          [`& .${tableCellClasses.root}:first-child`]: {
            width: '300px',
            color: '#787878',
          },
        }}
      >
        <TableBody>
          {backgroundResponse?.patientBackgrounds?.length ? (
            backgroundResponse.patientBackgrounds.map((curr) => (
              <PatientBackgroundInfo patientBackground={curr} />
            ))
          ) : (
            <NoBackgroundData />
          )}

          {Object.keys(categoryLookup).map((categoryKey) => {
            const category = categoryLookup[categoryKey];
            if (category.type !== CategoryType.WIM_CATEGORY_TYPE_RATING_SCALE) {
              return (
                <TableRow key={categoryKey.toLocaleLowerCase()}>
                  {rowHead(
                    categoryLookup[categoryKey].description,
                    CategoryTooltipText[categoryKey as CategoryName],
                  )}
                  {renderCategoryData(categoryKey)}
                </TableRow>
              );
            }

            return null;
          })}
          {configuringScales.length > 0 && (
            <TableRow key={CategoryName.WIM_CATEGORY_NAME_WHO5}>
              {rowHead('Rating Scale')}
              <PatientRatingScales ratingScales={applicableRatingScales} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Box>
  );
}
