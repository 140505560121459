import { useCallback, useEffect, useState } from 'react';
import { fetchPatientList, fetchPatientsCount } from 'pages/Dashboard/services/patient.services';
import { useQuery } from 'react-query';
import { patientUrls } from 'utils/apiUrls';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { useHttp } from 'hooks/use-fetch';

export interface TFilter {
  status?: string;
  lastName?: string;
}

type PatientsCount = { [key in PATIENT_LIST_VIEW]: number };

export const getAlphabetFilterList = (patients: PatientObj[]): string[] => {
  const patientNameFilterSet = new Set<string>();
  if (patients?.length > 0) {
    patients.forEach((p) => {
      if ((p.name?.lastName?.length ?? 0) > 0) {
        const firstChar = p.name?.lastName?.charAt(0).toLocaleUpperCase() ?? '';
        patientNameFilterSet.add(firstChar);
      }
    });
    return [...patientNameFilterSet];
  }
  return [];
};

export const getFilteredList = (allPatients: PatientObj[], lastName?: string) => {
  let filteredPatients = [...allPatients];
  if (lastName) {
    filteredPatients = filteredPatients
      .filter(
        ({ name }) => name?.lastName?.charAt(0)?.toLocaleUpperCase()
          === lastName?.toUpperCase(),
      );
  }
  const alphabetFilters = getAlphabetFilterList(filteredPatients);
  return {
    filteredPatients,
    alphabetFilters,
  };
};

export default function usePatientList(
  providerId: string,
  filters?: TFilter,
  queryKey?: string | null,
  searchQuery?: string,
  sortDirection?: string,
  sortField?: string,
  offset?: number,
  limit?: number,
  isMetadataLoading?: boolean,

) {
  const [patientList, setPatientList] = useState<PatientObj[]>([]);
  const [filteredPatientList, setFilteredPatientList] = useState<PatientObj[]>([]);
  const [alphabetFilterList, setAlphabetFilterList] = useState<string[]>([]);
  const [patientsCount, setPatientsCount] = useState<PatientsCount>({} as PatientsCount);
  const { http } = useHttp();

  const { isFetching: isFetchingPatientsCount, refetch: refetchPatientsCount } = useQuery(
    [`${patientUrls.patientsCount.queryUrl}`, providerId, filters?.status, queryKey],
    {
      queryFn: fetchPatientsCount(http.get),
      enabled: !!providerId && !!filters?.status,
      refetchOnMount: 'always',
      onSuccess({ statusCounts }) {
        const patientsCountMap = statusCounts.reduce((acc, { status, count }) => {
          acc[status] = count;
          return acc;
        }, {} as PatientsCount);
        setPatientsCount(patientsCountMap);
      },
    },
  );
  console.log({ searchQuery, sortDirection, sortField });
  const {
    isFetching: isFetchingPatientsList,
    isError: isErrorPatientsList,
    refetch: refetchPatientsList,
  } = useQuery(
    [patientUrls.listPatients.queryUrl,
      providerId,
      filters?.status,
      searchQuery,
      sortDirection,
      sortField,
      offset,
      limit,
      queryKey],
    {
      queryFn: fetchPatientList(http.get),
      enabled:
        !!providerId && !isMetadataLoading,
      refetchOnMount: 'always',
      onSuccess({ patients }) {
        setPatientList(patients);
      },
    },
  );

  const updateFilters = useCallback((updatedParents: PatientObj[], lastName?: string) => {
    const { filteredPatients, alphabetFilters } = getFilteredList(updatedParents, lastName);
    setAlphabetFilterList(alphabetFilters);
    setFilteredPatientList(filteredPatients);
  }, []);

  useEffect(() => {
    updateFilters(patientList, filters?.lastName);
  }, [filters?.lastName, patientList, updateFilters]);

  return {
    isFetching: isFetchingPatientsList || isFetchingPatientsCount || !!isMetadataLoading,
    isError: isErrorPatientsList,
    alphabetFilterList,
    patientList,
    filteredPatientList,
    patientsCount,
    refetchPatientsCount,
    refetchPatientsList,
  };
}
