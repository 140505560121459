import * as React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import { mainNav } from 'utils/spaUrls';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

interface TProps {
    statusFilter: string;
    lastNameFilter: string | undefined,
    alphabetFilterList: string[];
    patientsCount: {[key in PATIENT_LIST_VIEW]: number};
    setLastNameFilter: React.Dispatch<string>
    updateStatusFilter: React.Dispatch<string>
    isFetching: boolean;
}

export default function ListPatientFilters({
  statusFilter,
  lastNameFilter,
  alphabetFilterList,
  setLastNameFilter,
  patientsCount,
  updateStatusFilter,
  isFetching,
}: TProps) {
  const titleMap = {
    READY: 'Ready',
    PENDING: 'App signup pending',
    ERRORS: 'App invite errors',
  };
  return (
    <>
      <Grid container spacing={2} marginBottom={1}>
        {Object.values(PATIENT_LIST_VIEW).map((filter) => (
          <Grid item key={filter}>
            <Typography
              fontStyle='small'
              sx={{
                color: '#33739D',
                '&:hover': {
                  textDecoration: 'underline',
                },

                cursor: 'pointer',
                textDecoration: statusFilter === filter ? 'underline' : 'none',
              }}
              onClick={() => updateStatusFilter(filter)}
            >
              {`${titleMap[filter]} (${patientsCount[filter] || 0})`}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={1} />
    </>
  );
}
