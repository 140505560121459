import * as React from 'react';
import { Skeleton, TableCell, Typography } from '@mui/material';
import { useQuery } from 'react-query';
import { useHttp } from 'hooks/use-fetch';
import { useParams } from 'react-router-dom';
import { fetchWhoIamCategoryResults } from 'pages/Dashboard/services/whoiam.services';
import { whoIam } from 'utils/apiUrls';
import { NotSpecified } from 'utils/constants';

interface PatientCoreValuesProps {
  id: string;
}

export default function PatientCoreValues({ id }: PatientCoreValuesProps): JSX.Element {
  const { http, providerId } = useHttp();
  const { id: patienId } = useParams();

  const coreValues = useQuery(
    [`${whoIam.categoryResults.queryUrl}-${id}`, providerId, patienId,
      id],
    {
      queryFn: fetchWhoIamCategoryResults(http.get),
    },
  );


  if (coreValues.isFetching) {
    return <TableCell><Skeleton /></TableCell>;
  }

  const result = coreValues?.data?.results[0];

  return (
    <TableCell>
      <Typography>{result?.description || NotSpecified}</Typography>
    </TableCell>
  );
}
