import React, { useState } from 'react';
import { TextField, IconButton, InputAdornment, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

function SearchBar({ onSearch }: SearchBarProps) {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);
    onSearch(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center', // Center horizontally
        marginBottom: '16px', // Add some margin below if needed
        width: '100%', // Set the width to 100%
      }}
    >
      <TextField
        variant='outlined'
        placeholder='Search by name'
        value={searchTerm}
        onChange={handleSearchChange}
    //   fullWidth
        sx={{
        // Add custom styles for the search box
          width: '50%', // Set the width to 100%
          borderRadius: '44px', // Apply border radius here
          backgroundColor: '#FFFFFF', // Optional background color
          '& .MuiOutlinedInput-root': {
            borderRadius: '44px', // Apply border radius to the input
            height: '44px',
          },
          alignSelf: 'center',
          height: '44px',
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

export default SearchBar;
