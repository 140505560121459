import {
  PatientDemographicInfoPayload,
  PatientFormData,
  PatientInvitesPayload,
  ResendInvitePayload,
} from 'pages/Dashboard/types';
import {
  AddPatientPayload,
  PatientListResponse,
  PatientsCountResponse,
} from 'pages/Dashboard/types/patient.types';
import { QueryFunctionContext } from 'react-query';
import { patientUrls } from 'utils/apiUrls';
import { HttpType } from 'utils/http';

export const addPatient = (
  method: HttpType['post'],
  providerId: string,
  payload: PatientFormData,
) => method(patientUrls.createPatient.apiUrls(providerId).create, payload);

export const createPatient = (
  method: HttpType['post'],
  providerId: string,
  payload: PatientDemographicInfoPayload,
) => method(patientUrls.createPatient.apiUrls(providerId).create, payload);

export const invitePatients = (
  method: HttpType['post'],
  providerId: string,
  payload: PatientInvitesPayload,
) => method(patientUrls.invitePatients.apiUrls(providerId).create, payload);

export const fetchPatientList = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientListResponse> {
  const [, providerId, status, searchQuery, sortDirection, sortField, offset, limit] = queryKey;
  console.log(providerId, status, searchQuery, sortDirection, sortField);
  return method(
    patientUrls.listPatients.apiUrls(
        providerId as string,
        status as string,
        searchQuery as string,
        sortDirection as any,
        sortField as string,
        offset as number,
        limit as number,
    ).list,
  );
};

export const fetchPatientsCount = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<PatientsCountResponse> {
  const [, providerId] = queryKey;

  return method(patientUrls.patientsCount.apiUrls(providerId as string).count);
};

export const resendPatientInvite = (
  method: HttpType['post'],
  providerId: string,
  payload: ResendInvitePayload,
) => method(patientUrls.resendPatientInvite.apiUrls(providerId).create, payload);

export const fetchPatientDetails = (method: HttpType['get']) => function async({ queryKey }: QueryFunctionContext): Promise<AddPatientPayload> {
  const [, providerId, patientId] = queryKey;

  return method(
    patientUrls.patientDetails.apiUrls(providerId as string, patientId as string).details,
  );
};
