import React, { useState } from 'react';
import { TableRow, TableCell, IconButton, Typography, Box } from '@mui/material';
import { ReactComponent as SortIcon } from 'assets/SortIcon.svg';
import SortingIcon from 'pages/Dashboard/components/SortingIcon';

interface Header {
  label: string;
  field: string;
  isSortable: boolean;
  isCenter?: boolean;
}
interface SortableTableHeaderProps {
  statusFilter: string;
  sortDirection: string;
  sortField: string;
  setSortDirection: (direction: string) => void;
  setSortField: (field: string) => void;
}

export default function SortableTableHeader(props:SortableTableHeaderProps) {
  const { statusFilter, sortDirection, sortField, setSortDirection, setSortField } = props;
  // State to store the current sorted field and sort direction


  const headers: {[key:string]: Header[]} = {
    READY: [
      { label: 'Name', field: 'name', isSortable: true },
      { label: 'Date added', field: 'dateAdded', isSortable: true, isCenter: true },
      { label: 'Health records', field: 'healthRecordStatus', isSortable: true, isCenter: true },
      { label: 'Behavioral data', field: 'behavioralDataStatus', isSortable: true, isCenter: true },
      { label: 'Patient activity', field: 'patientActivity', isSortable: true },
      { label: '30-day change', field: 'thirtyDaysChange', isSortable: true },
    ],
    PENDING: [
      { label: 'Name', field: 'name', isSortable: true },
      { label: 'Date added', field: 'dateAdded', isSortable: true },
      { label: 'Action', field: 'behavioralDataStatus', isSortable: false },
    ],
    ERRORS: [
      { label: 'Name', field: 'name', isSortable: true },
      { label: 'Date added', field: 'dateAdded', isSortable: true },
      { label: 'Action', field: 'behavioralDataStatus', isSortable: false },
    ] };

  // Function to handle click on sorting
  const handleSortClick = (field: string) => {
    if (sortField === field) {
      // If the same field is clicked, toggle the sort direction
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    }
    else {
      // Otherwise, set the new sort field and reset direction to ascending
      setSortField(field);
      setSortDirection('asc');
    }
  };
  return (
    <TableRow
      sx={{ height: '20px', paddingTop: 3 }}
    >
      {headers?.[statusFilter]?.map(({ label, field, isSortable, isCenter }, index) => (
        <TableCell
          sx={{ padding: '4px', textAlign: 'center', cursor: 'pointer' }}
          key={field}
          onClick={() => {
            handleSortClick(field);
          }}
        >
          <Box style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: isCenter ? 'center' : 'flex-start' }}>
            <Typography
              color='#1E2731'
              sx={{
                fontWeight: 'bold', // Highlight sorted column
              }}
            >
              {label}
            </Typography>
            {isSortable && sortField === field && (
            <SortingIcon
              sortDirection={sortDirection}
              isFieldAffected={sortField === field}
            />
            )}
          </Box>
        </TableCell>
      ))}
    </TableRow>
  );
}
