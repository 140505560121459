import React from 'react';
import { PatientObj } from 'pages/Dashboard/types/patient.types';
import { Button, Chip, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { ReactComponent as CheckCircle } from 'assets/CheckCircleFilled.svg';
import { ReactComponent as TrendingUp } from 'assets/TrendingUpFilled.svg';
import { ReactComponent as TrendingDown } from 'assets/TrendingDownFilled.svg';
import { ReactComponent as NoData } from 'assets/NotEnoughData.svg';
import { ReactComponent as TrendingFlat } from 'assets/TrendingFlatFilled.svg';
import ResendInvite from 'pages/Dashboard/components/ResendInvite';

interface PatientComponentProps {
    patient: PatientObj;
}


function PendingPatientComponent(props: PatientComponentProps) {
  const { patient } = props;
  const isNamePending = !patient?.name?.firstName;

  // Convert phone number to string or display fallback if missing
  const phoneNumberString = `Pending(${String(patient.phoneNumber?.number || 'No Number Available')})`;

  console.log('patient', patient.onboardingMetadata?.status);

  return (
    <TableRow
      sx={{
        '&:hover': {
          backgroundColor: '#f2f2f2',
        },
      }}
    >
      <TableCell
        key={patient.id}
        scope='patient'
        sx={{
          color: isNamePending ? 'grey' : '#33739D',
          fontWeight: patient.hasRead ? 600 : 400,
          padding: '4px 8px',
        }}

      >
        {isNamePending ? phoneNumberString : `${patient.name?.firstName} ${patient.name?.lastName}`}
      </TableCell>
      <TableCell sx={{ padding: '4px 8px' }}>{patient.dateAdded ? new Date(patient.dateAdded).toLocaleDateString('en-US') : ''}</TableCell>
      <TableCell
        color='grey'
        sx={{ padding: '10px 8px' }}

      >
        {patient.onboardingMetadata?.status !== 'INVITE_DELIVERY_FAILED' ? <ResendInvite patient={patient} /> : (
          <Typography sx={{
            color: 'grey',
          }}
          >
            {' '}
            No action available while invite in progress
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
}


export default PendingPatientComponent;
