import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useMutation, useQueries, useQuery } from 'react-query';
import AddIcon from '@mui/icons-material/Add';
import { Box, Button, Grid, TablePagination, Typography, debounce } from '@mui/material';
import PageHeader from 'components/Common/PageHeader';
import { useHttp } from 'hooks/use-fetch';
import useSearchParamsState from 'hooks/use-search-params-state';
import ListPatientFilters from 'pages/Dashboard/components/ListPatientFilters';
import ListPatients from 'pages/Dashboard/components/ListPatientNew';
import { RefetchPatientsCtx } from 'pages/Dashboard/constants';
import { PATIENT_LIST_VIEW } from 'pages/Dashboard/enums';
import usePatientList from 'pages/Dashboard/hooks/usePatientList';
import {
  fetchProvidersAssociationsList,
  fetchUserMetadataWithKeys,
  postUserMetadata,
} from 'pages/Dashboard/services/provider.services';
import { providers } from 'utils/apiUrls';
import { mainNav } from 'utils/spaUrls';
import SearchBar from 'pages/Dashboard/components/SearchBar';
import { UserMetadata, UserMetadataFlags } from 'pages/Dashboard/types';

type PatientsCount = {
  [key: string]: number;
};
const batchSize = 50;

export default function DashboardV2() {
  const { http, providerId } = useHttp();

  const [statusFilter, setStatusFilter] = useSearchParamsState('status', PATIENT_LIST_VIEW.READY);
  const [lastNameFilter, setLastNameFilter] = useSearchParamsState('lastname', '');
  const [searchQuery, setSearchQuery] = React.useState('');
  const [sortField, setSortField] = React.useState('');
  const [sortDirection, setSortDirection] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(0);
  const handleSortFieldChange = (value: string) => {
    setAddPatientPreference(UserMetadataFlags.DefaultSortField, value);
    setSortField(value);
  };
  const handleSortDirectionChange = (value: string) => {
    setAddPatientPreference(UserMetadataFlags.DefaultSortDirection, value);
    setSortDirection(value);
  };

  const filters = React.useMemo(
    () => ({
      status: statusFilter,
      lastName: lastNameFilter,
    }),
    [lastNameFilter, statusFilter],
  );
  const processFlagData = async (data:UserMetadata) => {
    const finalData:UserMetadata = await data;
    if (finalData.flag.name === UserMetadataFlags.NumberOfRowsPerPage) {
      setRowsPerPage(parseInt(finalData.flag.value ?? '', 10) ?? 0);
    }
    if (finalData.flag.name === UserMetadataFlags.DefaultSortDirection) {
      setSortDirection(finalData.flag.value ?? '');
    }
    if (finalData.flag.name === UserMetadataFlags.DefaultSortField) {
      setSortField(finalData.flag.value ?? '');
    }
  };
  const metadataQueries = useQueries([
    {
      queryKey: [providerId, UserMetadataFlags.NumberOfRowsPerPage],
      queryFn: fetchUserMetadataWithKeys(http.post),
      onSuccess: processFlagData,
      onError: () => {
        // Handle error
        setRowsPerPage(batchSize);
      },
    },
    {
      queryKey: [providerId, UserMetadataFlags.DefaultSortDirection],
      queryFn: fetchUserMetadataWithKeys(http.post),
      onSuccess: processFlagData,
      onError: () => {
        // Handle error
        setSortDirection('asc');
      },
    },
    {
      queryKey: [providerId, UserMetadataFlags.DefaultSortField],
      queryFn: fetchUserMetadataWithKeys(http.post),
      onSuccess: processFlagData,
      onError: () => {
        // Handle error
        setSortField('name');
      },
    },
  ]);
  const [numberOfRows, sortDirectionVal, sortFieldVal] = metadataQueries;
  const isLoading = numberOfRows.isLoading || sortDirectionVal.isLoading || sortFieldVal.isLoading;

  const {
    isFetching,
    isError,
    alphabetFilterList,
    filteredPatientList,
    patientsCount,
    refetchPatientsList,
    refetchPatientsCount,
  } = usePatientList(
    providerId,
    filters,
    null,
    searchQuery,
    sortDirection,
    sortField,
    rowsPerPage * page,
    rowsPerPage,
    isLoading,
  );
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const providerAssociations = useQuery(
    [`${providers.providerAssociationList.queryUrl}`, providerId, 'dashboard'],
    {
      queryFn: fetchProvidersAssociationsList(http.get),
    },
  );

  const updateStatusFilter = React.useCallback(
    (updatedStatusFilter: string) => {
      setStatusFilter(updatedStatusFilter);
      setLastNameFilter('');
    },
    [setLastNameFilter, setStatusFilter],
  );

  const patientListTotalCount = React.useMemo(
    () => patientsCount[PATIENT_LIST_VIEW.READY] + patientsCount[PATIENT_LIST_VIEW.PENDING],
    [patientsCount],
  );

  const refetchPatients = React.useCallback(() => {
    refetchPatientsList();
    refetchPatientsCount();
  }, [refetchPatientsList, refetchPatientsCount]);

  const refetchPatientsCtx = React.useMemo(
    () => ({
      refetchPatients,
    }),
    [refetchPatients],
  );
  const setUserMetadataMutation = useMutation({
    mutationFn: (payload: UserMetadata) => postUserMetadata(http.post, { ...payload }),
  });

  const setAddPatientPreference = React.useCallback(
    (name: UserMetadataFlags, val: string) => {
      setUserMetadataMutation.mutate({
        userId: providerId,
        flag: {
          name,
          value: val,
        },
      });
    },
    [setUserMetadataMutation],
  );

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setAddPatientPreference(UserMetadataFlags.NumberOfRowsPerPage, event.target.value.toString());

    setPage(0); // Reset to first page when rows per page change
  };
  const currentPatientCount = searchQuery
    ? filteredPatientList.length
    // @ts-ignore
    : patientsCount?.[statusFilter] ?? (0 as number);
  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const debouncedSearchQuery = debounce(handleSearch, 500);
  const pages = currentPatientCount ? Math.ceil((currentPatientCount as number) / rowsPerPage) : 0;
  if (patientListTotalCount === 0) {
    return (
      <Box py={7.5} textAlign='center'>
        <Button
          component={RouterLink}
          size='large'
          startIcon={<AddIcon />}
          to={mainNav.addNewPatient}
          variant='contained'
          data-testid='add-first-patient-button'
        >
          ADD YOUR FIRST PATIENT
        </Button>
      </Box>
    );
  }

  return (
    <RefetchPatientsCtx.Provider value={refetchPatientsCtx}>
      <PageHeader title='Dashboard' />
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h2' fontWeight={400}>
            Patients
          </Typography>
        </Grid>
        <SearchBar onSearch={debouncedSearchQuery} />
        <Grid item xs={12} marginBottom={3}>
          <ListPatientFilters
            statusFilter={statusFilter}
            lastNameFilter={lastNameFilter}
            alphabetFilterList={alphabetFilterList}
            setLastNameFilter={setLastNameFilter}
            patientsCount={patientsCount}
            updateStatusFilter={updateStatusFilter}
            isFetching={isFetching}
          />
        </Grid>
      </Grid>
      <Box sx={{ paddingLeft: 2, backgroundColor: '#FFFFFF', paddingRight: 2 }}>
        <ListPatients
          filteredPatientList={filteredPatientList}
          isError={isError}
          isFetching={isFetching}
          setStatusFilter={setStatusFilter}
          showProvider={!!providerAssociations.data?.providers?.length}
          statusFilter={statusFilter}
          sortField={sortField}
          sortDirection={sortDirection}
          setSortDirection={handleSortDirectionChange}
          setSortField={handleSortFieldChange}
        />
      </Box>
      <TablePagination
        sx={{ backgroundColor: '#FFFFFF' }}
        component='div'
        count={currentPatientCount} // Total number of rows
        page={page} // Current page
        onPageChange={handleChangePage} // Handler for page change
        rowsPerPage={rowsPerPage} // Rows per page
        onRowsPerPageChange={handleChangeRowsPerPage} // Handler for changing rows per page
        rowsPerPageOptions={[10, 25, 50]} // Rows per page options
      />
    </RefetchPatientsCtx.Provider>
  );
}
